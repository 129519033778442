<template>
  <main>
    <PageTitle>{{ contentData.title[0].value }}</PageTitle>

    <ImageTriptych 
      :image="titleImage" 
      :title="contentData.field_untertitel.length ? contentData.field_untertitel[0].value : ''"
      :blurb="contentData.field_zusammenfassung.length ? contentData.field_zusammenfassung[0].value : ''"
    />

    <div
      ref="renderingsContainer"
      class="renderings__text-container text--z-depth-1"
    >
      <h3 ref="renderingsTitle">{{ contentData.field_renderings_titel[0].value }}</h3>
      <p ref="renderingsBody" v-html="contentData.field_renderings_koerpertext[0].value"></p>
    </div>
    <div
      ref="renderingsImg1"
      class="renderings__img renderings__img--1 z-depth-1"
      :style="{ backgroundImage: `url(${contentData.field_renderings_bilder[0].url})` }"
    ></div>
    <div
      ref="renderingsImg2"
      class="renderings__img renderings__img--2 z-depth-1"
      :style="{ backgroundImage: `url(${contentData.field_renderings_bilder[1].url})` }"
    ></div>

    <div
      ref="grundrisseImg"
      class="grundrisse__img"
      :style="{ backgroundImage: `url(${contentData.field_grundrisse_bild[0].url})` }"
    ></div>
    <div ref="grundrisseContainer" class="grundrisse__title text--z-depth-2">
      <h3 ref="grundrisseTitle">{{ contentData.field_grundrisse_titel[0].value }}</h3>
    </div>

    <div
      ref="virtualImg"
      class="virtual__img"
      :style="{ backgroundImage: `url(${contentData.field_vr_bild[0].url})` }"
    ></div>
    <div ref="virtualContainer" class="virtual__text-container text--z-depth-1">
      <h3 ref="virtualTitle">{{ contentData.field_vr_titel[0].value }}</h3>
      <p ref="virtualBody">{{ contentData.field_vr_koerpertext[0].value }}</p>
    </div>
  </main>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin";
import ScrollMagic from "scrollmagic";
import PageTitle from "@/components/PageTitle.vue";
import ImageTriptych from "@/components/ImageTriptych";
import { incrementComponentsLoading } from "@/events";

export default {
  mixins: [httpMixin],

  components: { PageTitle, ImageTriptych },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller(),
      titleImage: require("@/media/vis_title.png"),
      renderings: {
        title: "Renderings",
        bodyText: "Außen-/Innenansichten<br>Raumgestaltung",
        img1: require("@/media/vis_rendering1.png"),
        img2: require("@/media/vis_rendering2.png")
      },
      grundrisse: {
        title: "3D Grundrisse",
        img: require("@/media/vis_grundriss_preview.png")
      },
      virtualReality: {
        img: require("@/media/vis_virtual.png"),
        title: "Virtual Reality",
        body:
          "Die Virtual Reality Technologie visualisiert Ihr Projekt als vollständig gerenderten 3D Walk-Through. Erleben Sie ihr neues Eigenheim zu jeder Tageszeit aus jedem Blickwinkel."
      },

      contentData: {
        title: [{value: ""}],
        field_untertitel: [{value: ""}],
        field_zusammenfassung: [{value: ""}],
        field_renderings_titel: [{value: ""}],
        field_renderings_koerpertext: [{value: ""}],
        field_renderings_bilder: [{url: ""}, {url: ""}],
        field_grundrisse_titel: [{value: ""}],
        field_grundrisse_bild: [{url: ""}],
        field_vr_titel: [{value: ""}],
        field_vr_koerpertext: [{value: ""}],
        field_vr_bild: [{url: ""}]
      }
    };
  },
  
  created: function() {
    const self = this;
    this.componentsLoading++;
    this.fetchFromAPI("content/visualisierung")
      .then(content => {
        self.$set(this, "contentData", content[0]);
        self.componentsLoading--;
      })
      .catch(self.handleError);
  },

  mounted: function() {
    this.slideEnter(
      this.$refs.renderingsContainer,
      this.$refs.renderingsTitle,
      25
    );
    this.slideEnter(
      this.$refs.renderingsContainer,
      this.$refs.renderingsBody,
      50
    );
    this.slideEnter(
      this.$refs.renderingsContainer,
      this.$refs.renderingsImg1,
      75
    );
    this.slideEnter(
      this.$refs.renderingsContainer,
      this.$refs.renderingsImg2,
      100
    );

    this.slideEnter(
      this.$refs.grundrisseContainer,
      this.$refs.grundrisseImg,
      30
    );
    this.slideEnter(
      this.$refs.grundrisseContainer,
      this.$refs.grundrisseTitle,
      60
    );

    this.slideEnter(this.$refs.virtualContainer, this.$refs.virtualImg, 50);
    this.slideEnter(this.$refs.virtualContainer, this.$refs.virtualTitle, 30);
    this.slideEnter(this.$refs.virtualContainer, this.$refs.virtualBody, 60);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: all 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

.renderings__text-container {
  text-align: left;
}

.renderings__text-container > h3 {
  margin-block-start: 0;
  text-transform: uppercase;
}

.renderings__img {
  background-size: cover;
  background-position: center;
}

.renderings__img--1 {
  height: 42.86vw;
  margin-bottom: 16px;
}

.renderings__img--2 {
  margin-bottom: 40px;
}

.renderings__img--2,
.grundrisse__img {
  height: 42.9vw;
}

.grundrisse__title {
  text-transform: uppercase;
  text-align: left;
}

.grundrisse__img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  grid-row: 8 / 9;
}

.virtual__img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  grid-row: 10 / 11;
  height: 42vw;
  margin-top: 16px;
}

.virtual__text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}

.virtual__text-container > h3 {
  text-transform: uppercase;
  margin-block-end: 0;
}

@media screen and (min-width: 768px) {
  .renderings__img--1 {
    grid-column: 2 / 4;
    height: 28.6vw;
    margin-bottom: 0;
  }

  .renderings__img--2 {
    margin-bottom: 0;
  }

  .renderings__img--2,
  .grundrisse__img {
    grid-column: 1 / 4;
    height: 42.9vw;
  }

  .grundrisse__img {
    margin-left: 16.7vw;
    grid-row: 6 / 7;
    margin-top: 120px;
  }

  .grundrisse__title {
    grid-column: 1 / 2;
    text-align: center;
    grid-row: 6 / 7;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .virtual__text-container {
    grid-column: 2 / 4;
    margin-right: 12.5vw;
    padding-left: 8px;
    margin-top: 120px;
  }

  .virtual__img {
    grid-row: unset;
    margin-top: 120px;
    height: 18vw;
  }
}

@media screen and (min-width: 1025px) {
  .renderings__img--1 {
    height: 21.42vw;
    max-height: 309px;
  }

  .renderings__img--2,
  .grundrisse__img {
    height: 32.14vw;
    max-height: 463px;
  }

  .grundrisse__img {
    margin-left: 0;
  }

  .grundrisse__title {
    text-align: left;
  }

  .virtual__img {
    height: 25vw;
    max-height: 360px;
  }
}
</style>
